import { NavigationEnd } from '@angular/router';
import { createAction, props } from '@ngrx/store';

export const setBreadcrumbParts = createAction(
  '[Breadcrumb] Set parts',
  props<{
    event: NavigationEnd;
  }>(),
);

export const setBreadcrumbLastPart = createAction(
  '[Breadcrumb] Set last part',
  props<{
    name: string;
    route?: string;
  }>(),
);

export const setBreadcrumbDetails = createAction(
  '[Breadcrumb] Set details',
  props<{
    status: number | string;
    description: string;
  }>(),
);

export const setBreadcrumbSticky = createAction(
  '[Breadcrumb] Set sticky',
  props<{
    sticky: boolean;
  }>(),
);

export const clearPage = createAction('[Breadcrumb] Clear');
